import logo from './logo.svg';
import './App.css';
import Home from './Pages/Home';

function App() {
  return (
    <>
       {/* Arrtea.is-a.dev */}
      <Home></Home>
    </>

  );
}

export default App;
